<template>
  <div class="cornerstone-canvas-wrapper">
    <header v-if="advanced">
      <div class="buttons">
        <button
          class="reset"
          @click="reset"
          title="Върнете снимката в първоначалния вид"
        >
          <span class="icon">5</span>
          <p>
            Върни снимката в
            <br />оригиналния вид
          </p>
        </button>
      </div>
      <div class="buttons">
        <template v-if="showControlls">
          <button @click="negative" title="Негатив на снимката">
            <span class="icon">s</span>
            <p>
              Негатив на
              <br />снимката
            </p>
          </button>
          <button @click="hFlip" title="Хоризонтално завъртане">
            <span class="icon">t</span>
            <p>
              Завърти
              <br />хоризонтално
            </p>
          </button>
          <button @click="vFlip" title="Вертикално завъртане">
            <span class="icon">u</span>
            <p>
              Завърти
              <br />вертикално
            </p>
          </button>
          <button @click="rotate" title="Завъртане на 90 градуса">
            <span class="icon">v</span>
            <p>
              Завърти
              <br />на 90&deg;
            </p>
          </button>
        </template>
      </div>
      <div class="buttons">
        <button class="close" @click="$emit('close')" title="Затвори снимката">
          <span class="icon">L</span>
          <p>Затвори</p>
        </button>
      </div>
    </header>

    <div
      class="cornerstone-canvas--loading"
      :class="{ 'cornerstone-canvas--loaded': loaded }"
    >
      Зареждане...
    </div>
    <div
      class="cornerstone-canvas"
      :class="{ 'cornerstone-canvas--advanced': advanced }"
      :ref="`canvas-${this._uid}`"
      :id="`canvas-${this._uid}`"
      width="100%"
      oncontextmenu="return false"
      onmousedown="return false"
      @cornerstonetoolsmousewheel="updateZoomValue"
      @cornerstonetoolstouchpinch="updateZoomValue"
      @cornerstonetoolsmousedrag="updateDragValues"
      @cornerstonetoolstouchdrag="updateDragValues"
    ></div>
    <div class="sliders" v-if="advanced && loaded">
      <div class="slider-wrapper">
        <div class="slider">
          <div class="input-wrapper">
            <input
              type="range"
              min="-500"
              max="500"
              step="0.01"
              v-model="windowCenter"
            />
          </div>
        </div>
        <p>
          <img src="../assets/img/icons/brightness.svg" />
          <span>ЯРКОСТ</span>
        </p>
      </div>
      <div class="slider-wrapper">
        <div class="slider">
          <div class="input-wrapper">
            <input
              type="range"
              min="0.000001"
              max="5000"
              step="0.01"
              v-model="windowWidth"
            />
          </div>
        </div>
        <p>
          <img src="../assets/img/icons/contrast.svg" />
          <span>КОНТРАСТ</span>
        </p>
      </div>
      <div class="slider-wrapper">
        <div class="slider">
          <div class="input-wrapper">
            <input
              type="range"
              min="0.25"
              max="20"
              step="0.01"
              v-model="zoom"
            />
          </div>
        </div>
        <p>
          <img src="../assets/img/icons/zoom.svg" />
          <span>УВЕЛИЧЕНИЕ</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['path', 'advanced', 'category', 'download'],
  name: 'Cornerstone',
  data() {
    return {
      loaded: false,
      windowCenter: 2047,
      windowWidth: 4095,
      zoom: 0.4368932038834951,
    };
  },
  watch: {
    loaded(to) {
      if (to) {
        this.$emit('loaded', `canvas-${this._uid}`);
      }
    },
    windowCenter() {
      this.changeCenter();
    },
    windowWidth() {
      this.changeWidth();
    },
    zoom() {
      this.changeZoom();
    },
  },
  computed: {
    showControlls() {
      const allowed = [
        'Дигитална по Dieck',
        'Дигитална по паралелна техника',
        '/Bite-wing/ - L Left',
        '/Bite-wing/ - R Right',
      ];
      return allowed.indexOf(this.category) > -1;
    },
  },
  methods: {
    reset() {
      window.cornerstone.reset(this.$refs[`canvas-${this._uid}`]);
      this.updateZoomValue();
      this.updateDragValues();
    },
    negative() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.invert = !viewport.invert;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    hFlip() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.hflip = !viewport.hflip;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    vFlip() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.vflip = !viewport.vflip;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    rotate() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.rotation -= 90;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    changeCenter() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.voi.windowCenter = +this.windowCenter;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    changeWidth() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.voi.windowWidth = +this.windowWidth;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    changeZoom() {
      const viewport = window.cornerstone.getViewport(
        this.$refs[`canvas-${this._uid}`]
      );
      viewport.scale = this.zoom;
      window.cornerstone.setViewport(
        this.$refs[`canvas-${this._uid}`],
        viewport
      );
    },
    updateZoomValue() {
      if (this.advanced) {
        const viewport = window.cornerstone.getViewport(this.canvas);
        this.zoom = viewport.scale;
      }
    },
    updateDragValues() {
      if (this.advanced) {
        const viewport = window.cornerstone.getViewport(this.canvas);
        this.windowCenter = viewport.voi.windowCenter;
        this.windowWidth = viewport.voi.windowWidth;
      }
    },
  },
  mounted() {
    // Enable Canvas
    this.canvas = this.$refs[`canvas-${this._uid}`];
    const options = {};

    if (this.advanced) {
      // options.renderer = 'webgl';

      window.cornerstoneTools.init();

      // Grab Tool Classes
      const WwwcTool = window.cornerstoneTools.WwwcTool;
      const PanTool = window.cornerstoneTools.PanTool;
      const PanMultiTouchTool = window.cornerstoneTools.PanMultiTouchTool;
      const ZoomTool = window.cornerstoneTools.ZoomTool;
      const ZoomTouchPinchTool = window.cornerstoneTools.ZoomTouchPinchTool;
      const ZoomMouseWheelTool = window.cornerstoneTools.ZoomMouseWheelTool;

      // Add them
      window.cornerstoneTools.addToolForElement(this.canvas, PanTool);
      window.cornerstoneTools.addToolForElement(this.canvas, ZoomTool);
      window.cornerstoneTools.addToolForElement(this.canvas, WwwcTool);
      window.cornerstoneTools.addToolForElement(this.canvas, PanMultiTouchTool);
      window.cornerstoneTools.addToolForElement(
        this.canvas,
        ZoomTouchPinchTool
      );
      window.cornerstoneTools.addToolForElement(
        this.canvas,
        ZoomMouseWheelTool
      );

      // Set tool modes
      window.cornerstoneTools.setToolActiveForElement(this.canvas, 'Pan', {
        mouseButtonMask: 4,
      });
      window.cornerstoneTools.setToolActiveForElement(this.canvas, 'Zoom', {
        mouseButtonMask: 2,
      });
      window.cornerstoneTools.setToolActiveForElement(this.canvas, 'Wwwc', {
        mouseButtonMask: 1,
      });
      window.cornerstoneTools.setToolActiveForElement(
        this.canvas,
        'PanMultiTouch',
        {}
      );
      window.cornerstoneTools.setToolActiveForElement(
        this.canvas,
        'ZoomMouseWheel',
        {}
      );
      window.cornerstoneTools.setToolActiveForElement(
        this.canvas,
        'ZoomTouchPinch',
        {}
      );
    }

    window.cornerstone.enable(this.canvas, options);

    // // Load Image
    const protocol = this.path.match(/\.(dcm|DCM|dic|DIC|dicom|DICOM)$/)
      ? 'wadouri:'
      : '';

    let base = window.location.origin + '/uploads';
    if (process.env.VUE_APP_ENV === 'dev') {
      base = process.env.VUE_APP_UPLOADS_URI;
    }

    const imageId = `${protocol}${base}/photos/${this.path}`;

    window.cornerstone
      .loadImage(imageId)
      .then((image) => {
        if (this.download) {
          this.canvas.style.height = image.height + 'px';
          this.canvas.style.width = image.width + 'px';
        }

        window.cornerstone.displayImage(this.canvas, image);

        if (this.download) {
          window.cornerstone.resize(this.canvas, true);
        }

        this.$nextTick(() => {
          const viewport = window.cornerstone.getViewport(this.canvas);
          this.zoom = viewport.scale;
          this.windowCenter = viewport.voi.windowCenter;
          this.windowWidth = viewport.voi.windowWidth;

          this.loaded = true;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/slider.scss';
.cornerstone-canvas-wrapper {
  position: relative;
  header {
    align-items: center;
    background-color: #eaeaea;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 85vw;
    > img {
      height: 55px;
      width: auto;
      @include noselect();
    }
    .buttons {
      display: flex;
      button {
        align-items: center;
        background-color: #416184;
        border: 0;
        border-radius: 4px;
        display: inline-flex;
        color: #fff;
        height: 30px;
        justify-content: center;
        margin-left: 10px;
        // width: 30px;
        @include noselect();
        @include hover();
        > p {
          font-size: 10px;
          font-weight: 700;
          line-height: 10px;
          margin-left: 10px;
          text-align: left;
        }
        &.reset {
          background-color: #0ba490;
          .icon {
            font-size: 16px;
          }
        }
        &.close {
          background-color: #810900;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
  .cornerstone-canvas--advanced {
    // cursor: url('../assets/img/cursor.svg'), all-scroll;
    cursor: crosshair;
    width: 85vw;
    height: 70vh;
  }
}

.cornerstone-canvas--loading {
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  @include transition(all $transition $cubic);
}

.cornerstone-canvas--loaded {
  opacity: 0;
  z-index: -1;
}

.sliders {
  align-items: center;
  background-color: rgba($color: #eaeaea, $alpha: 0.8);
  border-radius: 4px;
  bottom: 10px;
  display: flex;
  justify-content: center;
  left: 25%;
  position: absolute;
  width: 50%;
  .slider-wrapper {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px 5px;
    width: 33%;
    > p {
      align-items: center;
      display: inline-flex;
    }
    img {
      height: 16px;
      margin-right: 5px;
      width: 16px;
    }
  }
  p {
    color: #000;
    font-size: 12px;
    font-weight: 700;
  }
}

@media screen and (max-width: $m) {
  .sliders {
    left: 15%;
    width: 70%;
  }
}

@media screen and (max-width: $s) {
  .cornerstone-canvas-wrapper {
    header {
      > img {
        height: 35px;
        width: auto;
      }
      .buttons {
        button {
          height: 25px;
          width: 25px;
          margin-left: 5px;
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
    .cornerstone-canvas--advanced {
      width: 85vw;
      height: 70vh;
    }
  }
  .sliders {
    left: 5%;
    width: 90%;
  }
}
@media screen and (max-width: $s) {
  button {
    > p {
      display: none;
    }
  }
  .cornerstone-canvas-wrapper {
    header {
      width: 100%;
      .buttons button.reset .icon {
        font-size: 13px;
      }
    }
    .cornerstone-canvas--advanced {
      height: calc(90vh - 45px);
      width: 100%;
    }
  }
  .sliders {
    left: 10px;
    width: calc(100% - 20px);
    .slider-wrapper {
      padding: 10px 0px;
      img {
        width: 12px;
        height: 12px;
      }
    }
    p {
      font-size: 10px;
      margin-top: 2px;
    }
  }
}
</style>
