<template>
  <div>
    <Cornerstone :path="path" @loaded="loaded" :download="true" />
  </div>
</template>
<script>
import Cornerstone from '@/components/Cornerstone.vue';
export default {
  components: {
    Cornerstone,
  },
  computed: {
    path() {
      return `${this.$route.params.examination}/${this.$route.params.photo}`;
    },
  },
  methods: {
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },
    downloadBlob(blob, name) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);


      // link.click();
      // window.URL.revokeObjectURL(blobUrl);
    },
    loaded(e) {
      const wrapper = document.getElementById(e);
      const canvases = wrapper.getElementsByTagName('canvas');

      if (canvases.length) {
        const canvas = canvases[0];

        setTimeout(() => {
          this.downloadBlob(
            this.dataURItoBlob(canvas.toDataURL('image/jpeg')),
            `${this.$route.params.photo}.jpg`
          );

          // setTimeout(() => {
            
            // close();
          // }, 100000);
        }, 100);
      }
    },
  },
};
</script>
